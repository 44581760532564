import * as React from 'react';
import BaseLayout from '../components/Layouts/BaseLayout';
import { Container } from 'reactstrap';
import * as aboutJson from '../data/about.json';
import Markdown from '../components/Markdown/Markdown';

export const AboutInternal = ({ aboutMarkdown, location }) => {
  return (
    <BaseLayout location={location} title="About Me">
      <header className="page-header small bg-secondary">
        <Container>
          <div className="header-content text-center">
            <h1 className="header-title bg-transparent">About Me</h1>
          </div>
        </Container>
      </header>

      <section className="big-section">
        <Container>
          <Markdown source={aboutMarkdown} />
        </Container>
      </section>
    </BaseLayout>
  );
};

export default ({ location }) => <AboutInternal location={location} {...aboutJson} />;
